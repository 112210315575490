import * as React from "react"
import {graphql} from "gatsby";
import {Container} from "../components/Container";
import NavigationBar from "../components/NavigationBar";
import Contact from "../components/Contact";
import SEO from "../components/SEO";

export default function({ data }) {
    const {markdownRemark} = data
    const {frontmatter, html} = markdownRemark

    return <Container>
        <NavigationBar/>
        <section className="mt-2">
            <div className="container-lg">
                <div className="row justify-content-center">
                    <div className="col-md-9 col-lg-7" dangerouslySetInnerHTML={{__html: html}}/>
                </div>
            </div>
        </section>
        <Contact type={frontmatter.slug} />
    </Container>
}

export const Head = () => <SEO title="Tarieven" />

// noinspection GraphQLUnresolvedReference
export const pageQuery = graphql`
    query {
        markdownRemark(frontmatter: {slug: {eq: "tarieven"}}) {
            html
            frontmatter {
                title
                slug
            }
        }
    }
`